<template>
    <!-- 전체 콘텐츠 영역 -->
    <div class="main-contents notice">
        <div class="mem_container">
            <div class="mem_info_contents">
                <div class="mem_contents_header">
                    <div class="title-txt">알림<br/>
                        <p><span class="prj_ct">{{totalCnt || 0}}</span>개의 알림이 있습니다!</p>
                    </div>
                </div>
                <scroll class="contents-body" :winScroll="true" @scrollBottom="scrollBottom">
                    <div class="notice_tit"><img src="/images/notice_sm.png" alt="전체알림"/>전체알림</div>
                                
                    <div v-for="item in list" :key="item.notiSeq" class="nt_list">
                        <div class="date">
                            {{item.regDate | date('yyyy년 MM월 dd일 KS요일')}}
                            <div class="new" v-if="item.notiNewYn == 'Y'">N</div>
                        </div>
                        <div class="speech-bubble" :title="item.notiCont" @click="clickNoti(item)" :class="{'btn_cursor':item.notiViewLinkUrl}">
                            <div class="point_gd" v-if="item.notiViewDisplayTypeCd == '02'">
                                <div class="image_box">
                                    <img v-if=" gender == 'M' && mberDivCd != '2'" src="/images/profile_df01.png" alt="프로필사진">
                                    <img v-else-if=" gender == 'F' && mberDivCd != '2'" src="/images/profile_df01_w.png" alt="프로필사진">
                                    <img v-else src="/images/profile_df02.png" alt="프로필사진">
                                </div>
                                <nbsp tagNm="div" class="greet" style="width: 790px;" :value="item.notiCont"/>
                            </div>
                            <div class="point_gd" v-else-if="item.notiViewDisplayTypeCd == '03'">
                                <div class="image_box">
                                    <img v-if=" gender == 'M'" src="/images/profile_df01.png" alt="프로필사진">
                                    <img v-if=" gender == 'F'" src="/images/profile_df01_w.png" alt="프로필사진">
                                </div>
                                <div class="greet">
                                    <template v-for="(str1, idx1) in item.notiCont.split('\n')">
                                        <template v-for="(str2, idx2) in str1.split('(P)')">
                                            <img src="/images/admin/point_s.png" alt="포인트" :key="idx1 + '_' + idx2" v-if="idx2 != 0"/>{{str2}}
                                        </template>
                                        <br :key="idx1"/>
                                    </template>
                                </div>
                                <!--div class="greet">반갑습니다. 김**님!<br/>기술신고서를 작성하고 <img src="/images/admin/point_s.png" alt="포인트"/>포인트를 적립받으세요!</div-->
                                <img class="point_bg" src="/images/notice_point_sm.png" alt="포인트배경"/>
                            </div>
                            <div v-else-if="item.notiViewLinkUrl == '/PRF101M01' && lastFixResume != item.notiSeq">
                                <nbsp tagNm="div" class="nt" :value="item.notiCont"/><p>수정완료</p>
                            </div>
                            <div v-else-if="item.notiViewLinkUrl == '/PRJ406M01'">
                                <nbsp tagNm="div" class="nt" :value="item.notiCont"/><p class="scoPrjList">목록가기 &#9654;</p>
                            </div>
                            <nbsp tagNm="div" class="nt" v-else :value="item.notiCont"/>
                        </div>
                    </div>
                </scroll>
            </div>
        </div>
    </div>
</template>

<script>
import scroll from "@/components/Scroll.vue";

export default {
    components: { scroll },
    data() {
        return {
            totalCnt: 0,
            newCnt: 0,
            list: [],
            nextKey: '',
            lastFixResume:'',
            gender: this.$store.state.userInfo.gender,
            mberDivCd : this.$store.state.userInfo.mberDivCd.substr(0,1),
        };
    },
    mounted(){
        this.search();
    },
    methods: {
        search(){
            if(this.nextKey === undefined) return;
			this.$.httpPost('/api/mem/myp/getNoti', {nextKey : this.nextKey})
				.then(res => {
					//console.log('RESULT :', res);
                    if(res.data && Array.isArray(res.data.list)){
                        this.$store.commit("notiNewCnt", 0);
                        this.totalCnt= res.data.notiCnt;
                        this.newCnt  = res.data.notiNewCnt;
                        this.list    = this.list.concat(res.data.list);
                        this.nextKey = res.data.nextKey;
                        if(this.$store.state.userInfo.mberDivCd == 11 && res.data.lastFix[0] != null) {
                            if(Object.values(res.data.lastFix[0]).length != 1) {
                                this.lastFixResume = res.data.lastFix[0].notiSeq;
                            }
                        }
                        // if(res.data.lastFix[0]) {
                        //     this.lastFixResume = res.data.lastFix[0].notiSeq;
                        // }
                    }
				})
				.catch( this.$.httpErrorCommon );
        },
        clickNoti(item){
            if(item.notiViewLinkUrl){
                if(item.notiViewLinkUrl.indexOf('http') == 0){
                    location.href = item.notiViewLinkUrl;
                } else {
                    if(this.lastFixResume == item.notiSeq || this.$store.state.userInfo.mberDivCd != 11) {
                        this.$router.push(item.notiViewLinkUrl);
                    }
                    // if(true) {
                        
                    // } else {
                    //     this.$router.push(item.notiViewLinkUrl);
                    // }
                    
                }
            }
        },
        scrollBottom(){
            this.search();
        }
    },
};
</script>